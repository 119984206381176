:deep(.ant-modal) {
  width: 10rem !important;
}

.tipDialog :deep(.ant-modal-body) {
  padding: 0.6rem 2rem 0.5rem;
  position: relative;
}

.tipDialog :deep(.ant-form-item-children) {
  display: flex;
}

.title {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0.45rem;
}

.title span {
  padding-bottom: 0.26rem;
  line-height: 1;
  position: relative;
  font-size: 0.32rem;
  color: #333333;
}

.title span::before {
  content: '';
  width: 1rem;
  height: 0.05rem;
  background-color: #00ffc0;
  border-radius: 0.02rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.content {
  text-align: center;
  font-size: 0.18rem;
  color: #333333;
  line-height: 1.667;
  padding-top: 0.15rem;
  margin-bottom: 0.3rem;
}

@media screen and (min-width: 1800px /*no*/) and (max-height: 800px /*no*/) {
  :deep(.ant-modal-content) {
    transform: scale(0.8);
  }
}