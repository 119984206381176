:deep(.breadcrumb) {
  border-bottom: 0;
  margin-bottom: 0.1rem;
}

.auto-1440 {
  padding-bottom: 0.8rem;
}

.title {
  padding-bottom: 0.1rem;
  border-bottom: 0.01rem solid #d5d5d5;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}

.title h1 {
  font-size: 0.2rem;
  color: #333333;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 0.15rem;
}

.title .handel {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.title .handel .item {
  margin-bottom: 0.1rem;
  line-height: 0.3rem;
  font-size: 0.18rem;
  color: #888888;
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
  transition: .3s;
}

.title .handel .item .svg-icon {
  margin-right: 0.1rem;
  color: #bcbcbc;
  transition: .3s;
}

.title .handel .item.active {
  color: #f49133;
}

.title .handel .item.active .svg-icon {
  color: #f49133;
}

.title .handel .collect {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.title .handel .collect:hover {
  color: #f49133;
}

.title .handel .collect:hover .svg-icon {
  color: #f49133;
}

.floor_list {
  margin-bottom: 0.5rem;
}

.publish_btn_box {
  position: absolute;
  right: -0.8rem;
  top: 1.3rem;
  height: 100%;
}

.publish_btn {
  width: 0.6rem;
  height: 0.6rem;
  padding: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.03rem;
  background-color: #e5e5e5;
  font-size: 0.18rem;
  color: #888888;
  transition: .3s;
  cursor: pointer;
  position: sticky;
  top: 1rem;
  right: 0;
}

.publish_btn:hover {
  color: #000;
  background-color: #00ffc0;
}

:deep(.pagenation) {
  margin-bottom: 0.6rem;
}

.btn {
  text-align: right;
  margin-top: 0.2rem;
}

.btn button {
  text-align: center;
  min-width: 1.2rem;
  line-height: 0.4rem;
  font-size: 0.18rem;
  color: #fff;
  border-radius: 0.03rem;
  background-color: #f49133;
  cursor: pointer;
  transition: .3s;
}

.btn button:hover {
  box-shadow: 0 0 0.1rem 0 #f49133;
}

:deep(.pagenation) {
  margin-bottom: 0;
}

#replayEditor {
  padding-top: 0.6rem;
}