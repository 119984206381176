.tag {
  font-size: 0.16rem;
  line-height: 0.3rem;
  border-radius: 0.15rem;
  margin-right: 0.05rem;
  padding: 0 0.16rem;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.1rem;
  color: #71899f;
  background-color: #dce8f3;
}

.tag[type=de1fault] {
  color: #71899f;
  background-color: #dce8f3;
}

.tag[type=pink] {
  background-color: #ebdede;
  color: #b98585;
}

.tag[type=purple] {
  background-color: #e0deeb;
  color: #908bab;
}

.tag[type=green] {
  background-color: #e4eedf;
  color: #79966a;
}