.floor {
  display: flex;
  padding-bottom: 0.2rem;
  margin-bottom: 0.2rem;
  border-bottom: 0.01rem solid #d5d5d5;
}

.floor .pic {
  width: 2.4rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.4rem;
  position: relative;
  overflow: hidden;
}

.floor .pic :deep(.ant-avatar) {
  margin-bottom: 0.2rem;
}

.floor .pic :deep(.icon_user) {
  font-size: 1rem;
}

.floor .pic .name {
  font-size: 0.2rem;
  color: #000000;
  margin-bottom: 0.1rem;
}

.floor .pic .tag {
  min-width: 1.2rem;
  line-height: 0.3rem;
  border-radius: 0.15rem;
  background-color: #f49133;
  color: #fff;
  text-align: center;
  padding: 0 0.15rem;
  font-size: 0.16rem;
  margin-bottom: 0.3rem;
}

.floor .pic .per_data {
  min-width: 1rem;
  font-size: 0.16rem;
  color: #555555;
  line-height: 1;
}

.floor .pic .per_data .item {
  margin-bottom: 0.1rem;
}

.floor .pic .per_data span {
  display: inline-block;
}

.floor .pic .per_data span:first-of-type {
  min-width: 0.5rem;
  padding-right: 0.05rem;
  border-right: 0.01rem solid #bbbbbb;
}

.floor .pic .per_data span:last-of-type {
  padding-left: 0.15rem;
}

.floor .pic .landlord {
  width: 0.7rem;
  height: 0.7rem;
  background-color: #f49133;
  color: #fff;
  text-align: center;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.45rem;
  position: absolute;
  left: -0.35rem;
  top: -0.35rem;
}

.floor .pic .landlord span {
  display: block;
  transform: rotate(-90deg);
  white-space: nowrap;
}

.floor .info {
  padding-left: 0.5rem;
  flex: 1;
}

.floor .info .title_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.16rem 0;
  border-bottom: 0.01rem solid #e5e5e5;
  margin-bottom: 0.3rem;
}

.floor .info .title_wrap .left {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  line-height: 1;
}

.floor .info .title_wrap .time {
  color: #888888;
  padding-right: 0.15rem;
  border-right: 0.01rem solid #e5e5e5;
}

.floor .info .title_wrap .floorNum {
  margin-left: 0.15rem;
}

.floor .info .title_wrap .only_author {
  padding-left: 0.15rem;
  color: #ff0000;
  cursor: pointer;
}

.floor .info .title_wrap .report {
  color: #888888;
  cursor: pointer;
}

.floor .info .content {
  padding-bottom: 0.5rem;
  border-bottom: 0.01rem solid #e5e5e5;
  margin-bottom: 0.2rem;
}

.floor .info .content img {
  height: auto !important;
}

.floor .info .handle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 0.68rem;
}

.floor .info .handle .item {
  margin-left: 0.4rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.floor .info .handle .item .svg-icon {
  color: #bbb;
  transition: .3s;
  margin-right: 0.1rem;
  display: block;
}

.floor .info .handle .item span {
  color: #555555;
  transition: .3s;
}

.floor .info .handle .item.active .svg-icon,
.floor .info .handle .item:hover .svg-icon {
  color: #f49133;
}

.floor .info .handle .item.active span,
.floor .info .handle .item:hover span {
  color: #f49133;
}

.floor .info .handle .item.active.object .svg-icon {
  color: #333;
}

.floor .info .handle .item.active.object span {
  color: #333;
}